import { getDevice } from './utils.js';
export let configuration = {
    svelteInstance: null,
    apiKey: undefined,
    device: getDevice(),
    initialWalletInit: [],
    gas: undefined,
    containerElements: { accountCenter: undefined, connectModal: undefined },
    unstoppableResolution: undefined,
    wagmi: undefined
};
export function updateConfiguration(update) {
    configuration = Object.assign(Object.assign({}, configuration), update);
}
