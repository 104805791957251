import { __rest } from "tslib";
import bowser from 'bowser';
import { hourglass, gnosisIcon, checkmark, errorIcon, infoIcon, ethereumIcon, polygonIcon, binanceIcon, questionIcon, fantomIcon, optimismIcon, celoIcon, avalancheIcon, harmonyOneIcon, arbitrumIcon, baseIcon, degenIcon, snaxIcon } from './icons/index.js';
export function getDevice() {
    if (typeof window !== 'undefined') {
        const parsed = bowser.getParser(window.navigator.userAgent);
        const os = parsed.getOS();
        const browser = parsed.getBrowser();
        const { type } = parsed.getPlatform();
        return {
            type: type,
            os: os,
            browser: browser
        };
    }
    else {
        return {
            type: null,
            os: null,
            browser: null
        };
    }
}
export const notNullish = (value) => value != null;
export function isSVG(str) {
    return str.includes('<svg');
}
export function shortenAddress(add) {
    return `${add.slice(0, 6)}…${add.slice(-4)}`;
}
export function shortenDomain(domain) {
    return domain.length > 11
        ? `${domain.slice(0, 4)}…${domain.slice(-6)}`
        : domain;
}
export async function copyWalletAddress(text) {
    try {
        const copy = await navigator.clipboard.writeText(text);
        return copy;
    }
    catch (err) {
        console.error('Failed to copy: ', err);
    }
}
export const toHexString = (val) => typeof val === 'number' ? `0x${val.toString(16)}` : val;
export function chainIdToHex(chains) {
    return chains.map((_a) => {
        var { id } = _a, rest = __rest(_a, ["id"]);
        const hexId = toHexString(id);
        return Object.assign({ id: hexId }, rest);
    });
}
export function gweiToWeiHex(gwei) {
    return `0x${(gwei * 1e9).toString(16)}`;
}
export const chainIdToLabel = {
    '0x1': 'Ethereum',
    '0xaa36a7': 'Sepolia',
    '0x38': 'Binance',
    '0x89': 'Polygon',
    '0xfa': 'Fantom',
    '0xa': 'OP Mainnet',
    '0x45': 'OP Kovan',
    '0xa86a': 'Avalanche',
    '0xa4ec': 'Celo',
    '0x2105': 'Base',
    '0x14a33': 'Base Goerli',
    '0x64': 'Gnosis',
    '0x63564C40': 'Harmony One',
    '0xa4b1': 'Arbitrum One',
    '0xa4ba': 'Arbitrum Nova',
    '0x27bc86aa': 'Degen',
    '0x890': 'SNAX'
};
export function validEnsChain(chainId) {
    // return L2s as Eth for ens resolution
    switch (chainId) {
        case '0x1':
        case '0x89': // Polygon
        case '0xa': //Optimism
        case '0xa4b1': // Arb
        case '0x144': // zksync
            return '0x1';
        case '0x5': // Goerli
            return chainId;
        case '0xaa36a7': // Sepolia
            return chainId;
        default:
            return null;
    }
}
export const chainIdToViemENSImport = async (chainId) => {
    switch (chainId) {
        case '0x89':
        case '0xa':
        case '0xa4b1':
        case '0x144':
        case '0x1': {
            const { mainnet } = await import('viem/chains');
            return mainnet;
        }
        case '0xaa36a7': {
            const { sepolia } = await import('viem/chains');
            return sepolia;
        }
        default:
            return null;
    }
};
export const networkToChainId = {
    main: '0x1',
    sepolia: '0xaa36a7',
    xdai: '0x64',
    'bsc-main': '0x38',
    'matic-main': '0x89',
    'fantom-main': '0xfa',
    'matic-mumbai': '0x80001',
    degen: '0x27bc86aa',
    SNAX: '0x890'
};
export const chainStyles = {
    '0x1': {
        icon: ethereumIcon,
        color: '#627EEA'
    },
    '0xaa36a7': {
        icon: ethereumIcon,
        color: '#627EEA'
    },
    '0x38': {
        icon: binanceIcon,
        color: '#F3BA2F'
    },
    '0x89': {
        icon: polygonIcon,
        color: '#8247E5'
    },
    '0xfa': {
        icon: fantomIcon,
        color: '#1969FF'
    },
    '0xa': {
        icon: optimismIcon,
        color: '#FF0420'
    },
    '0x45': {
        icon: optimismIcon,
        color: '#FF0420'
    },
    '0xa86a': {
        icon: avalancheIcon,
        color: '#E84142'
    },
    '0xa4ec': {
        icon: celoIcon,
        color: '#FBCC5C'
    },
    '0x64': {
        icon: gnosisIcon,
        color: '#04795B'
    },
    '0x63564C40': {
        icon: harmonyOneIcon,
        color: '#ffffff'
    },
    '0xa4b1': {
        icon: arbitrumIcon,
        color: '#33394B'
    },
    '0xa4ba': {
        icon: arbitrumIcon,
        color: '#33394B'
    },
    '0x2105': {
        icon: baseIcon,
        color: '#0259F9'
    },
    '0x14a33': {
        icon: baseIcon,
        color: '#0259F9'
    },
    '0x80001': {
        icon: polygonIcon,
        color: '#8247E5'
    },
    '0x27bc86aa': {
        icon: degenIcon,
        color: '#a36dfe'
    },
    '0x890': {
        icon: snaxIcon,
        color: '#00D1FF'
    }
};
export const unrecognizedChainStyle = { icon: questionIcon, color: '#33394B' };
export function getDefaultChainStyles(chainId) {
    return chainId ? chainStyles[chainId.toLowerCase()] : undefined;
}
export function connectedToValidAppChain(walletConnectedChain, chains) {
    return !!chains.find(({ id, namespace }) => id === walletConnectedChain.id &&
        namespace === walletConnectedChain.namespace);
}
export function initializeWalletModules(modules, device) {
    return modules.reduce((acc, walletInit) => {
        const initialized = walletInit({ device });
        if (initialized) {
            // injected wallets is an array of wallets
            acc.push(...(Array.isArray(initialized) ? initialized : [initialized]));
        }
        return acc;
    }, []);
}
export const defaultNotifyEventStyles = {
    pending: {
        backgroundColor: 'var(--onboard-primary-700, var(--primary-700))',
        borderColor: '#6370E5',
        eventIcon: hourglass
    },
    success: {
        backgroundColor: '#052E17',
        borderColor: 'var(--onboard-success-300, var(--success-300))',
        eventIcon: checkmark
    },
    error: {
        backgroundColor: '#FDB1B11A',
        borderColor: 'var(--onboard-danger-300, var(--danger-300))',
        eventIcon: errorIcon
    },
    hint: {
        backgroundColor: 'var(--onboard-gray-500, var(--gray-500))',
        borderColor: 'var(--onboard-gray-500, var(--gray-500))',
        iconColor: 'var(--onboard-gray-100, var(--gray-100))',
        eventIcon: infoIcon
    }
};
export const wait = (time) => new Promise(resolve => setTimeout(resolve, time));
export function getLocalStore(key) {
    try {
        const result = localStorage.getItem(key);
        return result;
    }
    catch (error) {
        return null;
    }
}
export function setLocalStore(key, value) {
    try {
        localStorage.setItem(key, value);
    }
    catch (error) {
        return;
    }
}
export function delLocalStore(key) {
    try {
        localStorage.removeItem(key);
    }
    catch (error) {
        return;
    }
}
